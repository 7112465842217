import React, { Fragment } from "react"
import { Styled } from "theme-ui"

export default () => (
  <Fragment>
     <Styled.a href="https://facebook.com/olo">Aleksander Adamowski</Styled.a>'s page.
    <br />
    Just some ramblings.
  </Fragment>
)
